import React, {Component} from 'react';

import './Servicos.scss';

import Translate from 'react-translate-component';
import counterpart from 'counterpart';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

import Header2 from '../Header2/Header2';


class Servicos extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	abreModalAereo() {
		document.querySelector('.modal_aereo').style.display = 'block';
		document.querySelector('.modal_maritimo').style.display = 'none';
		document.querySelector('.modal_rodoviario').style.display = 'none';
		document.querySelector('.modal_ferroviario').style.display = 'none';
		document.querySelector('.consultoria').style.display = 'none';
		document.querySelector('.desembaraco_aduaneiro').style.display = 'none';
		document.querySelector('.rastreamento_online').style.display = 'none';

	}
	abreModalMaritimo() {
		document.querySelector('.modal_aereo').style.display = 'none';
		document.querySelector('.modal_maritimo').style.display = 'block';
		document.querySelector('.modal_rodoviario').style.display = 'none';
		document.querySelector('.modal_ferroviario').style.display = 'none';
		document.querySelector('.consultoria').style.display = 'none';
		document.querySelector('.desembaraco_aduaneiro').style.display = 'none';
		document.querySelector('.rastreamento_online').style.display = 'none';
	}
	abreModalRodoviario() {
		document.querySelector('.modal_aereo').style.display = 'none';
		document.querySelector('.modal_maritimo').style.display = 'none';
		document.querySelector('.modal_rodoviario').style.display = 'block';
		document.querySelector('.modal_ferroviario').style.display = 'none';
		document.querySelector('.consultoria').style.display = 'none';
		document.querySelector('.desembaraco_aduaneiro').style.display = 'none';
		document.querySelector('.rastreamento_online').style.display = 'none';
	}
	abreModalFerroviario() {
		document.querySelector('.modal_aereo').style.display = 'blononeck';
		document.querySelector('.modal_maritimo').style.display = 'none';
		document.querySelector('.modal_rodoviario').style.display = 'none';
		document.querySelector('.modal_ferroviario').style.display = 'block';
		document.querySelector('.consultoria').style.display = 'none';
		document.querySelector('.desembaraco_aduaneiro').style.display = 'none';
		document.querySelector('.rastreamento_online').style.display = 'none';
	}
	abreConsultoria() {
		document.querySelector('.modal_aereo').style.display = 'none';
		document.querySelector('.modal_maritimo').style.display = 'none';
		document.querySelector('.modal_rodoviario').style.display = 'none';
		document.querySelector('.modal_ferroviario').style.display = 'none';
		document.querySelector('.consultoria').style.display = 'block';
		document.querySelector('.desembaraco_aduaneiro').style.display = 'none';
		document.querySelector('.rastreamento_online').style.display = 'none';
	}
	abreDesembaracoAduaneiro() {
		document.querySelector('.modal_aereo').style.display = 'none';
		document.querySelector('.modal_maritimo').style.display = 'none';
		document.querySelector('.modal_rodoviario').style.display = 'none';
		document.querySelector('.modal_ferroviario').style.display = 'none';
		document.querySelector('.consultoria').style.display = 'none';
		document.querySelector('.desembaraco_aduaneiro').style.display = 'block';
		document.querySelector('.rastreamento_online').style.display = 'none';
	}
	abreRastreamentoOnline() {
		document.querySelector('.modal_aereo').style.display = 'none';
		document.querySelector('.modal_maritimo').style.display = 'none';
		document.querySelector('.modal_rodoviario').style.display = 'none';
		document.querySelector('.modal_ferroviario').style.display = 'none';
		document.querySelector('.consultoria').style.display = 'none';
		document.querySelector('.desembaraco_aduaneiro').style.display = 'none';
		document.querySelector('.rastreamento_online').style.display = 'block';
	}


	render() {

	const capaServicos = counterpart.translate('banner.servicos');

		return(

			<div className="servicos">

				<Header2 />

				<div className="banner_servicos">
					<img className="img_pt" src={capaServicos} />
					<h2><Translate content="servicos.topo.primeiro" /><br/>
					<Translate content="servicos.topo.segundo" /></h2>
				</div>
				

				<div className="grid_servicos">

					<div className="menu_servicos">
						<ul>
							<li className="abre_modal_aereo" onMouseOver={() => this.abreModalAereo()}><Translate content="servicos.modalAereo.titulo" /></li>
							<li className="abre_modal_maritimo" onMouseOver={() => this.abreModalMaritimo()}><Translate content="servicos.modalMaritimo.titulo" /></li>
							<li className="abre_modal_rodoviario" onMouseOver={() => this.abreModalRodoviario()}><Translate content="servicos.modalRodoviario.titulo" /></li>
							<li className="abre_modal_ferroviario" onMouseOver={() => this.abreModalFerroviario()}><Translate content="servicos.modalFerroviario.titulo" /></li>
							<li className="abre_consultoria" onMouseOver={() => this.abreConsultoria()}><Translate content="servicos.consultoria.titulo" /></li>
							<li className="abre_desembaraco_aduaneiro" onMouseOver={() => this.abreDesembaracoAduaneiro()}><Translate content="servicos.desembaracoAduaneiro.titulo" /></li>
							<li className="abre_rastreamento_online" onMouseOver={() => this.abreRastreamentoOnline()}><Translate content="servicos.rastreamentoOnline.titulo" /></li>
						</ul>
					</div>


					<div className="item_modal modal_aereo">
						<h2><Translate content="servicos.modalAereo.titulo" /></h2>
						<p><Translate content="servicos.modalAereo.texto.primeiro" /></p>
						<p><Translate content="servicos.modalAereo.texto.segundo" /></p>
					</div>


					<div className="item_modal modal_maritimo">
						<h2><Translate content="servicos.modalMaritimo.titulo" /></h2>
						<p><Translate content="servicos.modalMaritimo.texto" /></p>
					</div>


					<div className="item_modal modal_rodoviario">
						<h2><Translate content="servicos.modalRodoviario.titulo" /></h2>
						<p><Translate content="servicos.modalRodoviario.texto" /></p>
					</div>



					<div className="item_modal modal_ferroviario">
						<h2><Translate content="servicos.modalFerroviario.titulo" /></h2>
						<p><Translate content="servicos.modalFerroviario.texto" /></p>
					</div>


					<div className="item_modal consultoria">
						<h2><Translate content="servicos.consultoria.titulo" /></h2>
						<p><Translate content="servicos.consultoria.texto.primeiro" /></p>
						<p>
						<Translate content="servicos.consultoria.texto.segundo" /><br/>
						<Translate content="servicos.consultoria.texto.terceiro" /><br/>
						<Translate content="servicos.consultoria.texto.quarto" /><br/>
						<Translate content="servicos.consultoria.texto.quinto" /><br/>
						<Translate content="servicos.consultoria.texto.sexto" /><br/>
						<Translate content="servicos.consultoria.texto.setimo" /><br/>
						<Translate content="servicos.consultoria.texto.oitavo" />
						</p>
					</div>


					<div className="item_modal desembaraco_aduaneiro">
						<h2><Translate content="servicos.desembaracoAduaneiro.titulo" /></h2>
						<p><Translate content="servicos.desembaracoAduaneiro.texto" /></p>
					</div>


					<div className="item_modal rastreamento_online">
						<h2><Translate content="servicos.rastreamentoOnline.titulo" /></h2>
						<p><Translate content="servicos.rastreamentoOnline.texto" /></p>
					</div>


				</div>


				<div className="separator"></div>


			</div>

		)
	}

}

export default Servicos;