import React, {Component} from 'react';

import './MercadosAtendidos.scss';

import Translate from 'react-translate-component';
import counterpart from 'counterpart';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

import UpdateImg from '../updateImg/updateImg';

import Header2 from '../Header2/Header2';

counterpart.registerTranslations('pt', pt);
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('es', es);


class MercadosAtendidos extends Component {

	onLangChange = (e) => {
	 	this.setState({
	 		lang: this.target.value
	 	});
	 	
	 	counterpart.setLocale(
	 		e.target.value
	 	);
	}


	componentDidMount() {
		window.scrollTo(0, 0);
	}



	render() {

		const imgBanner = counterpart.translate('banner.mercados');

		return(

			<div className="servicos">

				<Header2 />

				<div className="banner_servicos">
					<UpdateImg  />
				</div>

				<div className="grid_mercados_atendidos">

					<div className="menu_mercados_atendidos">
						<ul>
							<li className="abre_automotivo"><Translate content="mercados.automotivo" /></li>
							<li className="abre_bens_de_consumo"><Translate content="mercados.bens" /></li>
							<li className="abre_defesa_e_governo"><Translate content="mercados.defesa" /></li>
							<li className="abre_farmacos"><Translate content="mercados.farmacos" /></li>
							<li className="abre_industrial"><Translate content="mercados.industrial" /></li>
							<li className="abre_moda"><Translate content="mercados.moda" /></li>
							<li className="abre_quimicos"><Translate content="mercados.quimicos" /></li>
							<li className="abre_tecnologia"><Translate content="mercados.tecnologia" /></li>

							<li className="abre_alimenticios"><Translate content="mercados.alimenticios" /></li>
							<li className="abre_transporte"><Translate content="mercados.transporte" /></li>
							<li className="abre_biotecnologia"><Translate content="mercados.biotecnologia" /></li>
							<li className="abre_genetico"><Translate content="mercados.pesquisa" /></li>
							<li className="abre_medico_hospitalar"><Translate content="mercados.medico" /></li>
							<li className="abre_comunicacao"><Translate content="mercados.comunicacao" /></li>
							<li className="abre_maquinarios"><Translate content="mercados.maquinarios" /></li>
						</ul>
					</div>


				</div>


				<div className="separator"></div>


			</div>

		)
	}

}

export default MercadosAtendidos;