import React, {Component} from 'react';

import './Network.scss';

import Header2 from '../Header2/Header2';

import Translate from 'react-translate-component';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

import capaNetwork from '../../assets/img/bg-network.jpg';

import xlp from '../../assets/img/xlp.png';
import aog from '../../assets/img/aog.png';
import wf from '../../assets/img/wf.png'
import kilomaster from '../../assets/img/kilomaster.png'

class Network extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return(

			<div className="sobre">

				<Header2 />

				<div className="banner_network">
					<img className="img_pt" src={capaNetwork} />
				</div>

				<div className="conteudo_network">

					{/*<p>A atuação global da GP Cargo auxilia a logística e todo o transporte de carga independente do local de partida e do local de destino.</p>
					<p>Além disso o extenso Networking da GP Cargo contribui para facilitar todo o processo de transporte de cargas em qualquer parte do mundo.</p>*/}

					<h1><Translate content="network.titulo1" /></h1>

					<p><Translate content="network.texto.primeiro" /></p>

					<p><Translate content="network.texto.segundo" /></p>

					<p><Translate content="network.texto.terceiro" /></p>

					<p><Translate content="network.texto.quarto" /></p>

					<h1><Translate content="network.titulo2" /></h1>

					<div className="grupos">
						<div className="item_grupos">
							<img src={xlp} />
							{/*<legend>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna</legend>*/}
						</div>

						<div className="item_grupos">
							<img src={aog} />
							{/*<legend>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna</legend>*/}
						</div>

						<div className="item_grupos">
							<img src={wf} />
							{/*<legend>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna</legend>*/}
						</div>

						<div className="item_grupos">
							<img src={kilomaster} />
							{/*<legend>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna</legend>*/}
						</div>
					</div>




				</div>

				<div className="separator"></div>

			</div>

		)
	}
}

export default Network;