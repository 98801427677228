import React, {Component} from 'react';

import './Projetos.scss';

import Translate from 'react-translate-component';
import counterpart from 'counterpart';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

import Header2 from '../Header2/Header2';


class Projetos extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {

		const capaProjetos = counterpart.translate('banner.projetos');

		return(

			<div className="projetos">

				<Header2 />

				<div className="banner_projetos">
					<img className="img_pt" src={capaProjetos} />
				</div>

				<div className="conteudo_institucional bg_projetos">

					<div className="conteudo_projetos">

					<h1><Translate content="projetos.projetos.titulo" /></h1>
					<p><Translate content="projetos.projetos.texto.primeiro" /></p>

					<p><Translate content="projetos.projetos.texto.segundo" /></p>

					<h1><Translate content="projetos.etapas.titulo" /></h1>
					<p style={{width: '60%'}}>
						<Translate content="projetos.etapas.texto.primeiro" /><br/>
						<Translate content="projetos.etapas.texto.segundo" /><br/>
						<Translate content="projetos.etapas.texto.terceiro" /><br/>
						<Translate content="projetos.etapas.texto.quarto" />
					</p>

						{/*<p>Criamos soluções inteligentes e sob medida para cada cliente, gerenciando os projetos da concepção – levantando custos, analisando riscos e desenvolvendo planos de contingência – à finalização – proporcionando suporte jurídico, tributário e um planejamento de melhorias em cada uma das etapas da logística internacional.</p>

						<p>Oferecemos o agenciamento de cargas aéreas, marítimas, rodoviárias, multimodal, fretamento de navios e aeronaves, além de consultoria completa e personalizada em análise processual e desembaraço aduaneiro.</p>

						<p>Possuímos  nossa própria frota de caminhões, com o objetivo de finalizar o processo de importação ou iniciar o processo de exportação com excelência e dentro do prazo ideal.</p>

						<p>Temos expertise para atuar em diversos ramos da economia global, como cargas alimentícias, cargas perigosas, embarques com temperaturas controladas de -200o C a 25o C,  cargas a granel, obras de arte, parques industriais, locomotivas, cargas vivas, dentre outros.</p>*/}

					</div>

				</div>

				<div className="separator"></div>

			</div>

		)
	}
}

export default Projetos;