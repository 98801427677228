import React, {Component} from 'react';

import './Sobre.scss';

import Header2 from '../Header2/Header2';

import Translate from 'react-translate-component';
import counterpart from 'counterpart';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

import UpdateImg from '../updateImg/updateImg';

import capaInstituicional from '../../assets/img/capa-institucionalPT.jpg';
import videoInstituicional from '../../assets/img/video-institucional.jpg';
import greatPlaceToWork from '../../assets/img/great-place-to-work.png';
import boreauVeritas from '../../assets/img/boreau-veritas.png';
import aeo from '../../assets/img/aeo.png'

class Sobre extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {

		const capaSobre = counterpart.translate('banner.institucional');

		return(

			<div className="sobre">

				<Header2 />

				<div className="banner_institucional">
					<img src={capaSobre} />
					<iframe src="https://www.youtube.com/embed/ybiQCwNjHNo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>

				<div className="conteudo_institucional">

					<div className="item_institucional">
						<div className="conteudo_item_institucional">
							<h1><Translate content="institucional.titulos.quemSomos" /></h1>

							<p><Translate content="institucional.textos.quemSomos.primeiro" /></p>
							<p><Translate content="institucional.textos.quemSomos.segundo" /></p>
							<p><Translate content="institucional.textos.quemSomos.terceiro" /></p>
							<p><Translate content="institucional.textos.quemSomos.quarto" /></p>
						</div>
					</div>


					<div className="item_institucional">
						<div className="conteudo_item_institucional">
							<h1><Translate content="institucional.titulos.valores" /></h1>
							<p><Translate content="institucional.textos.valores.primeiro" /></p>
							<p>
								<ul>
									<li><Translate content="institucional.textos.valores.segundo" /></li>
									<li><Translate content="institucional.textos.valores.terceiro" /></li>
									<li><Translate content="institucional.textos.valores.quarto" /></li>
									<li><Translate content="institucional.textos.valores.quinto" /></li>
									<li><Translate content="institucional.textos.valores.sexto" /></li>
								</ul>
							</p>
						</div>
					</div>


					<div className="item_institucional">
						<div className="conteudo_item_institucional">
							<div className="certificacoes">
								<h1><Translate content="institucional.titulos.certificacoes" /></h1>
								<p><Translate content="institucional.textos.certificacoes.primeiro" /></p>

								<p><Translate content="institucional.textos.certificacoes.segundo" /><br/>
								<Translate content="institucional.textos.certificacoes.terceiro" /><br/>
								<Translate content="institucional.textos.certificacoes.quarto" /></p>
								
								<img src={greatPlaceToWork} />
								<img src={boreauVeritas} />
								<img src={aeo} />
							</div>
						</div>
					</div>

					<div className="item_institucional">
						<div className="conteudo_item_institucional">
							<h1><Translate content="institucional.titulos.missao" /></h1>

							<p><Translate content="institucional.textos.missao" /></p>
						</div>
					</div>

					
						<div className="conteudo_item_institucional">
							<h1><Translate content="institucional.titulos.politica" /></h1>

							<p><Translate content="institucional.textos.politica" /></p>
						</div>


				</div>

				<div className="separator"></div>

			</div>

		)
	}
}

export default Sobre;