import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from 'react-slick';
import axios from 'axios';
import $ from 'jquery';
import Recaptcha from 'react-recaptcha';
import Contato from '../Contato/contato';

import counterpart from 'counterpart';
import Translate from 'react-translate-component';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

import './Home.scss';

import logo01 from '../../assets/img/gp-cargo01.png';
import logoInterna from '../../assets/img/gp-cargo02.png';
import quimicos from '../../assets/img/icons/01-quimicos.png';
import automotivo from '../../assets/img/icons/02-automotivo-home.png';
import moda from '../../assets/img/icons/03-moda-home.png';
import industrial from '../../assets/img/icons/04-industrial-home.png';
import defesaEGoverno from '../../assets/img/icons/05-defesa-e-governo-home.png';
import tecnologia from '../../assets/img/icons/06-tecnologia-home.png';
import bensEConsumo from '../../assets/img/icons/07-bens-e-consumos-home.png';
import farmacos from '../../assets/img/icons/08-farmacos-home.png';
import underBanner from '../../assets/img/under-banner.png';

import Sobre from '../Sobre/Sobre';
import Network from '../Network/Network';
import Servicos from '../Servicos/Servicos';
import Projetos from '../Projetos/Projetos';
import MercadosAtendidos from '../MercadosAtendidos/MercadosAtendidos';
import Footer from '../Footer/Footer';

counterpart.registerTranslations('pt', pt);
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('es', es);

// counterpart.setLocale('pt');

class Home extends Component {

	state = {
		lang: 'pt',
		banner: {
			mercados: 'hide'
		},
		isLoaded: false
	}

	onLangChange = (e) => {
	 	this.setState({lang: e.target.value});
	 	counterpart.setLocale(e.target.value);
	}

	constructor() {
		super();

		this.state = {
			nome: '',
			email: '',
			telefone: '',
			mensagem: ''
		}	
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange = e => {
		this.setState({[e.target.name]: e.target.value})
	}

	async handleSubmit(e) {
		e.preventDefault()

		const { nome, email, telefone, mensagem } = this.state;

		const form = await axios.post('/api/form', {
			nome, 
			email,
			telefone,
			mensagem
		})
	}


	componentDidMount() {

		// Aguarda a pagina carregar por completo (document ready)
		$(document).ready(function($){
			// Captura o post do formulário
		    $("form[name=formlogin]").on('submit', function(e){
		    	// Previne o post por padrão
		        e.preventDefault();
		        // Ve se preencheu todos os campos do formulário
		        if ($("form[name=formlogin] input[name=login]").val() === '' || $("form[name=formlogin] input[name=senha]").val() === '') {
		        	alert("Preencha o seu login (e-mail) e sua senha");
		            return false;
		        } else {
		        	var login = $("form[name=formlogin] input[name=login]").val();
		            var senha = $("form[name=formlogin] input[name=senha]").val();
		        }
		        // Faz o post por ajax, usando o endereço do form e os dados acima
		        $.post($("form[name=formlogin]").attr("action"), { login: login, senha: senha })
		        .done(function(data){
		        	// Se tiver a palavra "ERRO" no retorno, mostra o alerta
		        	if (data.indexOf("ERRO") >= 0) {
		            	alert(data);
		            } else {
		            // Senão, deve ser o token. Redireciona pra aplicação.
		            	window.open("https://gplog.gpcargo.com.br/login?token=" + data);
		            }
		        })
		        .fail(function(data){
		        	// Se deu algum erro no script, alerta e mostra no console.
		        	alert("Falha ao efetuar login. Confira seus dados.");
		            console.log(data);
		        });
		    });
		});
		
		if(window.pageXOffset < 1200) {
			window.addEventListener('scroll', function() {
				if(window.pageYOffset > 200) {
					document.querySelector('.logo_mobile').classList.add('opacity');
				}
				else {
					document.querySelector('.logo_mobile').classList.remove('opacity');
				}
			})
		}
		
		 
			window.addEventListener('scroll', function() {
				if(window.pageYOffset > 200 && document.querySelector('.header_home')) {
					document.querySelector('.header_home').classList.add('header_scroll');
					document.querySelector('.logo_header_home').classList.add('opacity');
				}
				else {
					if (document.querySelector('.header_home')) {
						document.querySelector('.header_home').classList.remove('header_scroll');
						document.querySelector('.logo_header_home').classList.remove('opacity');
					}
				}
			})
		 
	
	}

	abreRastreamento() {
		document.querySelector('.rastreamento').classList.toggle('abre_rastreamento');
	}


	abre_menu() {
	 	var menu = document.querySelector('.burger_menu');
		var menuMobile = document.querySelector('.menu_mobile');

		menu.classList.toggle('active');
		menuMobile.classList.toggle('menuActive');
		document.querySelector('.grid_menu_mobile').classList.toggle('opacity');
	}

	topFunction() {
	  	document.body.scrollTop = 0;
	  	document.documentElement.scrollTop = 0;
	}


	atualiza() {
		// window.location.reload();
	}

	render() {

		const settings = {
	      dots: true,
	      infinite: true,
	      speed: 500,
	      slidesToShow: 1,
	      slidesToScroll: 1
	   };

		const nome = counterpart.translate('contato.nome');
		const email = counterpart.translate('contato.email');
		const telefone = counterpart.translate('contato.telefone');
		const mensagem = counterpart.translate('contato.mensagem');

		return(
			<div className="home" id="home">

				<div className="header_home">

					<div className="conteudo_header_home">
						<div onClick={this.topFunction} className="logo_header_home" to="/home">
							<img src={logo01} />
						</div>

						<div className="grid_header_home">
							<Link to="/sobre" ><Translate content="menu.institucional" /></Link>
							<Link to="/network" Component={Network}><Translate content="menu.network" /></Link>
							<Link to="/servicos"><Translate content="menu.servicos" /></Link>
							<Link to="/projetos"><Translate content="menu.projetos" /></Link>
							<Link to="/mercados-atendidos"><Translate content="menu.mercados" /></Link>
							<HashLink to="/home/#contato"><Translate content="menu.contato" /></HashLink>
							<a href="https://www.gpcargo.com.br/blog/"><Translate content="menu.blog" /></a>
							{/*<Link to="/blog">BLOG</Link>*/}
							<a href="https://www.gpcargo.com.br/intranet/"><Translate content="menu.intranet" /></a>
							<a href="https://gpcargo.solides.jobs/"><Translate content="menu.trabalhe" /></a>

							<div className="change_lang">
								<button type="submit" value="pt" onClick={this.onLangChange}>PT</button>
								<button type="submit" value="en" onClick={this.onLangChange}>EN</button>
								<button type="submit" value="es" onClick={this.onLangChange}>ES</button>
							</div>
							

							<div>
								<a className="btn_rastreamento" href="#" onClick={this.abreRastreamento}><Translate content="menu.rastreamento" /></a>
	
								<form method="POST" action="https://gplog.gpcargo.com.br/gplog2web/login" name="formlogin"className="rastreamento">
									<input type="text" name="login" placeholder="Login" />
									<input type="password" name="senha" placeholder="Senha" />
									<button name="go" value="ENTRAR"><Translate content="buttons.entrar" /></button>
								</form>
								
							</div>					

						</div>
					</div>

				</div>




				<div className="header_home_mobile">

					<div className="grid_header_home_mobile">
						<div className="logo_mobile">
							<img src={logo01} />
						</div>

						<div className="burger_menu" onClick={this.abre_menu}>
							<span></span>
						</div>
					</div>

					<div className="menu_mobile">
							<div className="grid_menu_mobile">
								<Link to="/sobre" ><Translate content="menu.institucional" /></Link>
								<Link to="/network" Component={Network}><Translate content="menu.network" /></Link>
								<Link to="/servicos"><Translate content="menu.servicos" /></Link>
								<Link to="/projetos"><Translate content="menu.projetos" /></Link>
								<Link to="/mercados-atendidos"><Translate content="menu.mercados" /></Link>
								<HashLink to="/home/#contato"><Translate content="menu.contato" /></HashLink>
								<a href="https://www.gpcargo.com.br/blog/"><Translate content="menu.blog" /></a>
								<a href="https://gpcargo.solides.jobs/"><Translate content="menu.trabalhe" /></a>
								
								<div className="change_lang_interna_mobile">
									<button type="submit" value="pt" onClick={this.onLangChange}>PT</button>
									<button type="submit" value="en" onClick={this.onLangChange}>EN</button>
									<button type="submit" value="es" onClick={this.onLangChange}>ES</button>
								</div>

								{/*<div>
									<a className="btn_rastreamento_interna_mobile" onClick={this.abreRastreamentoInterna}><Translate content="menu.rastreamento" /></a>
									<div className="rastreamento_interna_mobile">
										<input placeholder="Login" />
										<input placeholder="Senha" />
										<a href="https://gplog.gpcargo.com.br/#/login"><button><Translate content="buttons.entrar" /></button></a>
									</div>
								</div>*/}
								
							</div>
							
						</div>

				</div>


				<Slider {...settings}>
					<div className="banner_home banner01" >

						<div className="grid_banner01">
							<img src={logo01} />
							<h1><Translate content="titulos.servicos" /></h1>
							<p><Translate content="home.banner.txtBannerServicos" /></p>
							<Link to="/servicos"><Translate content="buttons.saibaMais" /></Link>
						</div>
					</div>
					<div className="banner_home banner02">
						<div className="grid_banner01">
							<img src={logo01} />
							<h1><Translate content="titulos.institucional" /></h1>
							<p><Translate content="home.banner.txtBannerInstitucional" /></p>
							<Link to="/sobre" ><Translate content="buttons.saibaMais" /></Link>
						</div>
					</div>
				</Slider>



				<div className="projetos_home">
					
					<div className="grid_projetos_home">
						<ScrollAnimation delay={200} animateIn='fadeInRight'>
							<h1><Translate content="titulos.projetos" /></h1>
							<p><Translate content="home.txtProjetos" /></p>
							<Link className="button_ver_mais_verde" to="/projetos"><Translate content="buttons.saibaMais" /></Link>
						</ScrollAnimation>
					</div>
					
				</div>


				<div className="informacoes_tecnicas_home">
					<div className="grid_informacoes_tecnicas_home">
						<ScrollAnimation delay={200} animateIn='fadeInLeft'>
							<h1><Translate content="titulos.networking" /></h1>
							<div className="txt_informacoes_tecnicas_home">
								<Link to="/network"><Translate content="buttons.saibaMais" /></Link>
								<p><Translate content="home.txtNetworking" /></p>
							</div>
						</ScrollAnimation>
					</div>					
				</div>


				<div className="mercados_atendidos_home">
					<div className="grid_mercados_atendidos_home">
						<ScrollAnimation delay={200} animateIn='fadeIn'>
							<h1><Translate content="titulos.mercados" /></h1>
						</ScrollAnimation>
						<div className="itens_mercados_atendidos_home">

							<div className="item_mercados_atendidos_home">
								<ScrollAnimation delay={300} animateIn='fadeIn'>
									<img src={quimicos} />
									<legend><Translate content="mercados.quimicos" /></legend>
								</ScrollAnimation>
							</div>

							
							<div className="item_mercados_atendidos_home">
								<ScrollAnimation delay={400} animateIn='fadeIn'>
									<img src={automotivo} />
									<legend><Translate content="mercados.automotivo" /></legend>
								</ScrollAnimation>
							</div>
							

							<div className="item_mercados_atendidos_home">
								<ScrollAnimation delay={500} animateIn='fadeIn'>
									<img src={moda} />
									<legend><Translate content="mercados.moda" /></legend>
								</ScrollAnimation>
							</div>

							
							<div className="item_mercados_atendidos_home">
								<ScrollAnimation delay={600} animateIn='fadeIn'>
									<img src={industrial} />
									<legend><Translate content="mercados.industrial" /></legend>
								</ScrollAnimation>
							</div>
							

							<div className="item_mercados_atendidos_home">
								<ScrollAnimation delay={700} animateIn='fadeIn'>
									<img src={defesaEGoverno} />
									<legend><Translate content="mercados.defesa" /></legend>
								</ScrollAnimation>
							</div>						

							
							<div className="item_mercados_atendidos_home">
								<ScrollAnimation delay={800} animateIn='fadeIn'>
									<img src={tecnologia} />
									<legend><Translate content="mercados.tecnologia" /></legend>
								</ScrollAnimation>
							</div>

							
							<div className="item_mercados_atendidos_home">
								<ScrollAnimation delay={900} animateIn='fadeIn'>
									<img src={bensEConsumo} />
									<legend><Translate content="mercados.bens" /></legend>
								</ScrollAnimation>
							</div>
							

							<div className="item_mercados_atendidos_home">
								<ScrollAnimation delay={1000} animateIn='fadeIn'>
									<img src={farmacos} />
									<legend><Translate content="mercados.farmacos" /></legend>
								</ScrollAnimation>
							</div>
						
						</div>
						<Link style={{marginLeft: '1em'}} className="button_ver_mais_verde" to="/mercados-atendidos"><Translate content="buttons.saibaMais" /></Link>
					</div>
				</div>


				<div className="contato_home" id="contato">
					<div className="grid_contato_home">
						<ScrollAnimation delay={200} animateIn='fadeIn'>
							
							<Contato />

						</ScrollAnimation>
					</div>				
				</div>

			</div>
		)
	}

}

export default Home;