import React from 'react';
import { BrowserRouter, Switch, Route, HashRouter } from 'react-router-dom';

import './App.scss';

import Home from './inc/Home/Home';
import Sobre from './inc/Sobre/Sobre';
import Network from './inc/Network/Network';
import Servicos from './inc/Servicos/Servicos';
import Projetos from './inc/Projetos/Projetos';
import MercadosAtendidos from './inc/MercadosAtendidos/MercadosAtendidos';
import Contato from './inc/Contato/contato';
import Footer from './inc/Footer/Footer';

function App() {
  return (
    <div className="App">
       <HashRouter>
            <Switch>    
              <Route path="/" exact={true} component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/sobre" component={Sobre} />
              <Route path="/network" component={Network} />
              <Route path="/servicos" component={Servicos} />
              <Route path="/projetos" component={Projetos} />
              <Route path="/mercados-atendidos" component={MercadosAtendidos} />
            </Switch>
         <Footer />
        </HashRouter>
    </div>
  );
}

export default App;
