import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import './Footer.scss';

import Translate from 'react-translate-component';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

import logoFooter from '../../assets/img/logo-gp-cargo-footer.png';
import youtube from '../../assets/img/icons/youtube.png';
import face from '../../assets/img/icons/face.png';
import wtspp from '../../assets/img/icons/wtspp.png';
import linkedin from '../../assets/img/icons/linkedin.png';

class Footer extends Component {

	render(){
		return(

			<div className="footer">

				<div className="grid_footer">
					<div className="logo_footer">
						<img src={logoFooter} />
					</div>

					<div>
					</div>

					<div>
					</div>

					<ul className="home_footer">
						<h4><Translate content="menu.home" /></h4>
						<li><Link to="/sobre"><Translate content="menu.institucional" /></Link></li>
						<li><Link to="/network"><Translate content="menu.network" /></Link></li>
						<li><Link to="/servicos"><Translate content="menu.servicos" /></Link></li>
						<li><Link to="/projetos"><Translate content="menu.projetos" /></Link></li>
					</ul>

					<div className="itens_footer">
						<ul>
							<li><Link to="/mercados-atendidos"><Translate content="menu.mercados" /></Link></li>
							<li><HashLink to="/home/#contato"><Translate content="menu.contato" /></HashLink></li>
							
						</ul>
						<div className="social_footer">
							<a target="_blank" href="https://www.youtube.com/channel/UC3jizmuXL2Z_zezdq5CoZfA"><img src={youtube} /></a>
							<a target="_blank" href="https://www.facebook.com/gpcargobrasil/"><img src={face} /></a>
							<a target="_blank" href="https://www.linkedin.com/company/gp-cargo/"><img src={linkedin} /></a>
						</div>
					</div>

				</div>

				<div className="endereco_footer">
					<div className="grid_endereco_footer">
						<div></div>
						<p>Rua Teixeira de Freitas, 478 - CJ 501<br/>
						Santo Antônio - Belo Horizonte - MG - <Translate content="footer.brasil" /></p>
						
						<p>+55 31 3025 5001</p>

						<p>contato@gpcargo.com.br</p>
					</div>
				</div>

				<div className="copy">
					<p>© 2022 GP Cargo </p>
				</div>

			</div>

		)
	}

}

export default Footer;