import React, { Component } from 'react';



import './contato.scss';

import $ from 'jquery';

import Translate from 'react-translate-component';
import counterpart from 'counterpart';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

import Header2 from '../Header2/Header2.js'

var Recaptcha = require('react-recaptcha');

class Contato extends Component {
	constructor(props) {
		super(props);
		this.state = {
			atualizar: false
		}
	}

	atualiza(atualizar) {
		window.location.reload(atualizar);
	}

	componentDidMount() {
		
	}


	render() {

	const nome = counterpart.translate('contato.nome');
	const email = counterpart.translate('contato.email');
	const telefone = counterpart.translate('contato.telefone');
	const mensagem = counterpart.translate('contato.mensagem');


	return(
		<div>

			<Header2 />

			<h1><Translate content="titulos.contato" /></h1>

			<form action="/controller/controllerForm.php" name="formlogin2" method="POST" >
				<div>
					<input name="nome" placeholder={nome} onChange={this.handleChange}></input>
					<input name="email" placeholder={email} onChange={this.handleChange}></input>
					<input name="telefone" placeholder={telefone} onChange={this.handleChange}></input>
				</div>
				<div className="form_box2">
					<div>
						<textarea name="mensagem" placeholder={mensagem} onChange={this.handleChange}></textarea>
					</div>
					<div className="box_captcha">

						{/*<div className="g-recaptcha" data-sitekey="6Lf2h6YZAAAAAEX2CAvLRD0_C9gP0COhu-jNr0L5">
						</div>*/}

						 <Recaptcha sitekey="6Lf2h6YZAAAAAEX2CAvLRD0_C9gP0COhu-jNr0L5" hl={'pt-br'} />

						<button><Translate content="buttons.enviar" /></button>
					</div>
				</div>
			</form>

		</div>
	)
	}
}

export default Contato;