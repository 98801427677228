import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import $ from 'jquery';

import './Header2.scss';
import '../MercadosAtendidos/MercadosAtendidos.scss';


import counterpart from 'counterpart';
import Translate from 'react-translate-component';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

import logoInterna from '../../assets/img/gp-cargo02.png';
import logo01 from '../../assets/img/gp-cargo01.png';

import Home from '../Home/Home';
import Sobre from '../Sobre/Sobre';
import Network from '../Network/Network';
import Servicos from '../Servicos/Servicos';
import Projetos from '../Projetos/Projetos';
import MercadosAtendidos from '../MercadosAtendidos/MercadosAtendidos';
import Contato from '../Contato/contato';
import Footer from '../Footer/Footer';

counterpart.registerTranslations('pt', pt);
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('es', es);

counterpart.setLocale('pt');

class Header2 extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
			lang: pt,
			bannerImg: '',
		}
	}

	captcha() {
		window.location.reload();
	}

	atualiza() {

	}
	

	componentDidMount() {

		return(<Contato />)

		// Aguarda a pagina carregar por completo (document ready)
		$(document).ready(function($){
			// Captura o post do formulário
		    $("form[name=formlogin]").on('submit', function(e){
		    	// Previne o post por padrão
		        e.preventDefault();
		        // Ve se preencheu todos os campos do formulário
		        if ($("form[name=formlogin] input[name=login]").val() === '' || $("form[name=formlogin] input[name=senha]").val() === '') {
		        	alert("Preencha o seu login (e-mail) e sua senha");
		            return false;
		        } else {
		        	var login = $("form[name=formlogin] input[name=login]").val();
		            var senha = $("form[name=formlogin] input[name=senha]").val();
		        }
		        // Faz o post por ajax, usando o endereço do form e os dados acima
		        $.post($("form[name=formlogin]").attr("action"), { login: login, senha: senha })
		        .done(function(data){
		        	// Se tiver a palavra "ERRO" no retorno, mostra o alerta
		        	if (data.indexOf("ERRO") >= 0) {
		            	alert(data);
		            } else {
		            // Senão, deve ser o token. Redireciona pra aplicação.
		            	window.open("https://gplog.gpcargo.com.br/login?token=" + data);
		            }
		        })
		        .fail(function(data){
		        	// Se deu algum erro no script, alerta e mostra no console.
		        	alert("Falha ao efetuar login. Confira seus dados.");
		            console.log(data);
		        });
		    });
		});

	}



	abreRastreamentoInterna() {
		document.querySelector('.rastreamento_interna').classList.toggle('abre_rastreamento_interna');
	}

	abre_menu() {
	 // 	var menu = document.querySelector('.burger_menu');
		// var menuMobile = document.querySelector('.menu_mobile');

		// menu.classList.toggle('active');
		// menuMobile.classList.toggle('menuActive');
		// document.querySelector('.grid_menu_mobile').classList.toggle('opacity');
	}

	topFunction2() {
	  	document.body.scrollTop = 0;
	  	document.documentElement.scrollTop = 0;
	}

	onLangChange = (e) => {
		this.setState({
			lang: e.target.value,
		});
		counterpart.setLocale(e.target.value);
		this.render();
	}


	render() {
		return(
			<div className="header_interno">
				<div className="header2">

					<div className="conteudo_header2">

						<Link to="/home" onClick={this.topFunction2} className="logo_header_interna">
							<img src={logoInterna} />
						</Link>
						
						<div className="grid_header_interna">	
							<Link to="/sobre" ><Translate content="menu.institucional" /></Link>
							<Link to="/network" Component={Network}><Translate content="menu.network" /></Link>
							<Link to="/servicos"><Translate content="menu.servicos" /></Link>
							<Link to="/projetos"><Translate content="menu.projetos" /></Link>
							<Link to="/mercados-atendidos"><Translate content="menu.mercados" /></Link>
							{/*<Link to="/contato"><Translate content="menu.contato" /></Link>*/}
							<HashLink onClick={this.atualiza} to="/home/#contato">
								<Translate content="menu.contato" />
							</HashLink>
							<a href="https://www.gpcargo.com.br/blog/"><Translate content="menu.blog" /></a>
							
							{/*<div className="change_lang_interna">
								<button type="submit" value="pt" onClick={this.Pt}>PT</button>
								<button type="submit" value="en" onClick={this.En}>EN</button>
								<button type="submit" value="es" onClick={this.onLangChange}>ES</button>
							</div>*/}

							<div>
								<a className="btn_rastreamento_interna" onClick={this.abreRastreamentoInterna}><Translate content="menu.rastreamento" /></a>

								<form method="POST" action="https://gplog.gpcargo.com.br/gplog2web/login" name="formlogin"className="rastreamento_interna">
									<input type="text" name="login" placeholder="Login" />
									<input type="password" name="senha" placeholder="Senha" />
									<button name="go" value="ENTRAR"><Translate content="buttons.entrar" /></button>
								</form>

								{/*<div className="rastreamento_interna">
									<input placeholder="Login" />
									<input placeholder="Senha" />
									<a href="https://gplog.gpcargo.com.br/#/login"><button><Translate content="buttons.entrar" /></button></a>
								</div>*/}

							</div>
							
						</div>

					</div>

				</div>



				<div className="header_home_mobile">

					<div className="grid_header_home_mobile">
						<Link to="/home" className="logo_mobile">
							<img src={logo01} />
						</Link>

						<div className="burger_menu" onClick={() => this.abre_menu()}>
							<span></span>
						</div>
					</div>

					<div className="menu_mobile">
							<div className="grid_menu_mobile">
								<Link to="/sobre" ><Translate content="menu.institucional" /></Link>
								<Link to="/network" Component={Network}><Translate content="menu.network" /></Link>
								<Link to="/servicos"><Translate content="menu.servicos" /></Link>
								<Link to="/projetos"><Translate content="menu.projetos" /></Link>
								<Link to="/mercados-atendidos"><Translate content="menu.mercados" /></Link>
								<HashLink to="/home/#contato"><Translate content="menu.contato" /></HashLink>
								<a href="https://www.gpcargo.com.br/blog/"><Translate content="menu.blog" /></a>
								
								{/*<div className="change_lang_interna_mobile">
									<button type="submit" value="pt" onClick={this.onLangChange}>PT</button>
									<button type="submit" value="en" onClick={this.onLangChange}>EN</button>
									<button type="submit" value="es" onClick={this.onLangChange}>ES</button>
								</div>*/}

								<div>
									<a className="btn_rastreamento_interna_mobile" onClick={this.abreRastreamentoInterna}><Translate content="menu.rastreamento" /></a>
									<div className="rastreamento_interna_mobile">
										<input placeholder="Login" />
										<input placeholder="Senha" />
										<a href="https://gplog.gpcargo.com.br/#/login"><button><Translate content="buttons.entrar" /></button></a>
									</div>
								</div>
								
							</div>
							
						</div>

						{/*<div className="menu_mobile">
							<div className="grid_menu_mobile">
								<Link to="/sobre" ><Translate content="menu.institucional" /></Link>
								<Link to="/network" Component={Network}><Translate content="menu.network" /></Link>
								<Link to="/servicos"><Translate content="menu.servicos" /></Link>
								<Link to="/projetos"><Translate content="menu.projetos" /></Link>
								<Link to="/mercados-atendidos"><Translate content="menu.mercados" /></Link>
								<HashLink to="/home/#contato"><Translate content="menu.contato" /></HashLink>
								<a href="https://www.gpcargo.com.br/blog/"><Translate content="menu.blog" /></a>
								
								<div className="change_lang_interna_mobile">
									<button type="submit" value="pt" onClick={this.onLangChange}>PT</button>
									<button type="submit" value="en" onClick={this.onLangChange}>EN</button>
									<button type="submit" value="es" onClick={this.onLangChange}>ES</button>
								</div>

								<div>
									<a className="btn_rastreamento_interna_mobile" onClick={() => this.abreRastreamentoInterna()}>RASTREAMENTO</a>
									<div className="rastreamento_interna_mobile">
										<input placeholder="Login" />
										<input placeholder="Senha" />
										<a href="https://gplog.gpcargo.com.br/#/login"><button>Entrar</button></a>
									</div>
								</div>
								
								<a className="btn_rastreamento btn_rastreamento_mobile" href="#" onClick={() => this.abreRastreamentoMobile()}>RASTREAMENTO</a>
								<div className="rastreamento rastreamento_mobile">
									<input placeholder="Login" />
									<input placeholder="Senha" />
									<button>Entrar</button>
								</div>
								
							</div>

							<select value={this.state.lang} onChange={this.onLangChange}>
								<option value="pt">PT</option>
								<option value="en">EN</option>
								<option value="es">ES</option>
							</select>
							
						</div>*/}

					</div>
				</div>
		)
	}
}

export default Header2;