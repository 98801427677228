export default {
	menu: {
		home: 'Home',
		institucional: 'INSTITUCIONAL',
		network: 'NETWORK',
		servicos: 'SERVIÇOS',
		projetos: 'PROJETOS',
		mercados: 'MERCADOS ATENDIDOS',
		contato: 'CONTATO',
		blog: 'BLOG',
		intranet: 'INTRANET',
		trabalhe: 'TRABALHE CONOSCO',
		rastreamento: 'RASTREAMENTO'
	},

	banner: {
		institucional: require('../assets/img/capa-institucionalPT.jpg'),
		servicos: require('../assets/img/capa-servicosPT.jpg'),
		projetos: require('../assets/img/capa-projetosPT.jpg'),
		mercados: require('../assets/img/capa-mercados-atendidosPT.jpg'),
	},

	buttons: {
		saibaMais: 'Saiba Mais',
		enviar: 'Enviar',
		entrar: 'Entrar'
	},

	titulos: {
		servicos: 'Serviços',
		institucional: 'Institucional',
		projetos: 'Projetos',
		networking: 'Newtworking Internacional',
		mercados: 'Mercados',
		contato: 'Contato'
	},

	home: {
		banner: {
			txtBannerServicos: 'Oferecendo alta qualidade nos serviços prestados, preços competitivos e soluções logísticas ao cliente, a GP Cargo tem conquistado seu lugar no mercado e demonstrado um diferencial nunca antes visto no ramo de agenciamento de cargas.',
			txtBannerInstitucional: 'Com sua matriz em Belo Horizonte, MG, a GP Cargo possui parceiros globais altamente capacitados que juntamente com a GP Cargo, oferecem todos os serviços integrados à cadeia logística, honrando o nível de qualidade proposto por ela.'
		},
		txtProjetos: 'Paralelamente aos embarques regulares, a GP Cargo é detentora de uma grande experiência para gerenciar embarques de carga de projetos.',
		txtNetworking: 'Nossa atuação global facilita a operação em conjunto com a logística integrada de nossos clientes.'
	},

	institucional: {
		titulos: {
			quemSomos: 'Quem Somos',
			valores: 'Valores',
			certificacoes: 'Certificações',
			missao: 'Missão',
			politica: 'Política de Qualidade'
		},
		textos: {
			quemSomos: {
				primeiro: 'Somos a GP Cargo, um agente internacional de cargas especialista em projetos logísticos complexos, análise de logística integrada, desembaraço aduaneiro e transporte nacional e internacional.',
				segundo: 'Iniciamos nossas atividades em 2009, com nossa matriz localizada em Belo Horizonte, MG, e, a partir daí, passamos a conquistar representantes e parceiros altamente capacitados em todo o território brasileiro e no mundo.',
				terceiro: 'Ao completar 10 anos em 2019, reformulamos nossa marca e encaramos a inovação, nos preparando para mais 10 anos de conquistas.',
				quarto: 'Para isso, estamos cada vez mais conscientes de que somos movidos a desafios e valorizamos a busca incessante de soluções arrojadas para os processos de nossos clientes. E continuaremos a oferecer soluções completas porta a porta, proporcionando facilidade no entendimento de processos robustos de comércio exterior.' 
			} ,
			valores: {
				primeiro: 'Nossos valores são:',
				segundo: '- ética;',
				terceiro: '- transparência e confiança nas relações;',
				quarto: '- excelência operacional;',
				quinto: '- respeito com a equipe e desenvolvimento de pessoal;',
				sexto: '- responsabilidade social.'
			} ,
			certificacoes: {
				primeiro: 'Fomos um dos primeiros agentes de carga do Brasil a receber da Receita Federal Brasileira o título de Operador Econômico Autorizado. Temos muito orgulho desta certificação que atesta a seriedade e qualidade encontradas em nossos serviços.',
				segundo: 'Somos, também, certificados ISO 9001:2015 desde 2015.',
				terceiro: 'Empresa GPTW desde 2017.',
				quarto: 'E possuímos a certificação do grupo AOG como um agente apto a trabalhar com cargas críticas urgentes.'
			},
			missao: 'Através do crescimento integral da nossa equipe, aprofundar no negócio dos nossos clientes, promover a excelência dos nossos parceiros no mundo todo, para oferecer soluções realmente inteligentes.',
			politica: 'Mergulhamos nos processos de nossos clientes, analisando e entendendo suas necessidades. Assim, simplificamos para melhorar e personalizamos para inovar, garantindo que a movimentação de bens seja realizada de maneira eficiente, com a comunicação adequada entre todos os envolvidos, evitando desperdício de tempo, recursos e fornecendo, portanto, a nossa garantia de qualidade.'
		},

			network: {
				primeiro: 'Nossa atuação global facilita a operação em conjunto com a logística integrada de nossos clientes. Independentemente do local de partida, armazenagem, trânsito e chegada, atuamos com o mesmo padrão de qualidade proposto.',
				segundo: 'Assim como a excelência operacional é um de nossos valores, promover também a excelência de nossos parceiros no mundo todo faz parte da nossa missão, resultando na garantia de qualidade que repassamos para nossos clientes.',
				terceiro: 'Somos parte da rede internacional de agentes qualificados WFG (World Freight Group) e também certificados para operação de cargas AOG (Aircraft On Ground), o que nos levou a integrar o network AOG Freight 247 e a poder contar com a parceria de agentes especializados neste segmento de cargas.',
				quarto: 'Integramos também o seleto grupo de operadores logísticos XLP, especializado em cargas de grande porte e projetos especiais.'
			},
		},

		network: {
			titulo1: 'Networking Internacional',
			titulo2: 'Grupos',
			texto: {
				primeiro: 'Nossa atuação global facilita a operação em conjunto com a logística integrada de nossos clientes. Independentemente do local de partida, armazenagem, trânsito e chegada, atuamos com o mesmo padrão de qualidade proposto.',
				segundo: 'Assim como a excelência operacional é um de nossos valores, promover também a excelência de nossos parceiros no mundo todo faz parte da nossa missão, resultando na garantia de qualidade que repassamos para nossos clientes.',
				terceiro: 'Somos parte da rede internacional de agentes qualificados WFG (World Freight Group) e também certificados para operação de cargas AOG (Aircraft On Ground), o que nos levou a integrar o network AOG Freight 247 e a poder contar com a parceria de agentes especializados neste segmento de cargas.',
				quarto: 'Integramos também o seleto grupo de operadores logísticos XLP, especializado em cargas de grande porte e projetos especiais.',
			}
		},

		servicos: {
			topo: {
				primeiro: 'Oferecemos serviços de alta qualidade a preços competitivos.',
				segundo: 'Conheça nossas áreas de atuação:'
			},
			modalAereo: {
				titulo: 'MODAL AÉREO',
				texto: {
					primeiro: 'Somos um agente credenciado IATA-CASS e atuamos em parceria com as principais companhias aéreas do mundo. Assim, podemos oferecer um transporte seguro, tanto na importação quanto na exportação, para os principais aeroportos mundiais.',
					segundo: 'Nossos serviços vão desde embarques regulares até a contratação de charters para a movimentação de cargas urgentes de qualquer tamanho ou quantidade.'
				}
			},
			modalMaritimo: {
				titulo: 'MODAL MARÍTIMO',
				texto: 'Nossos contratos são firmados com armadores de primeira linha e atuamos em todos os tipos de embarque, desde o simples (LCL’S) até os mais complexos, como fretamentos de navios, embarques de cargas (RO/RO), Break-bulk e bulk.'
			},
			modalRodoviario: {
				titulo: 'MODAL RODOVIÁRIO',
				texto: 'Em parceria com companhias rodoviárias selecionadas, atuamos na contratação e coordenação de fretes e no transporte rodoviário de cargas de grande porte para transporte de projetos.'
			},
			modalFerroviario: {
				titulo: 'MODAL FERROVIÁRIO ',
				texto: 'Contamos com estreito relacionamento com as principais concessionárias, o que nos permite oferecer maior segurança e preços competitivos no transporte em importações e exportações.'
			},
			consultoria: {
				titulo: 'CONSULTORIA ',
				texto: {
					primeiro: 'Possuímos uma linha de consultoria especializada em:',
					segundo: '- análise da logística integrada;',
					terceiro: '- execução do trabalho de procurement;',
					quarto: '- estudo logístico de embarques;',
					quinto: '- estudo prévio do projeto junto às autoridades alfandegárias para o desembaraço;',
					sexto: '- consultoria tributária, trabalhando possíveis benefícios fiscais de que o cliente possa usufruir;',
					setimo: '- assessoria jurídica na análise de contratos internacionais;',
					oitavo: '- seguros internacionais especializados para quaisquer tipos de embarque e carga.'
				}
			},
			desembaracoAduaneiro: {
				titulo: 'DESEMBARAÇO ADUANEIRO',
				texto: 'Contamos com uma equipe especializada em uma das etapas mais críticas da cadeia logística internacional: o desembaraço aduaneiro. Seja em exportações ou importações regulares, passando por regimes especiais até o estudo e execução de benefícios tributários nas importações. Assim, estamos capacitados a atender qualquer tipo de demanda neste quesito.'
			},
			rastreamentoOnline: {
				titulo: 'RASTREAMENTO ONLINE',
				texto: 'Através de um sistema inteligente de rastreamento, o GP LOG, oferecemos aos nossos clientes informações diárias sobre o andamento de seus projetos, localização de suas cargas e acesso rápido a toda sua documentação.'
			},

		},

		projetos: {
			projetos: {
				titulo: 'Projetos',
				texto: {
					primeiro: 'Possuímos experiência para gerenciar embarques de carga de projetos. Este tipo de carga tem destaque especial dentro da nossa empresa devido à sua complexidade.',
					segundo: 'Nosso foco é buscar a excelência em qualidade desde a implementação até a conclusão de cada projeto. Para isso, unimos o conhecimento da nossa equipe a ferramentas de análise quantitativa focadas em elevar a qualidade, reduzir os custos e o impacto ambiental.'
				}
			},
			etapas: {
				titulo: 'Etapas dos Nossos Projetos',
				texto: {
					primeiro: '1. Reuniões para entendimento das especificidades do projeto e das necessidades dos nossos clientes.',
					segundo: '2. Análise de custos, riscos e demandas.',
					terceiro: '3. Desenvolvimento do plano tático, estratégico e de contingenciamento, com avaliação do projeto junto aos departamentos jurídico, aduaneiro, tributário e contábil.',
					quarto: '4. Acompanhamento e execução do projeto.',
				}
			}
		},

		mercados: {
			automotivo: 'AUTOMOTIVO',
			bens: 'BENS DE CONSUMO',
			defesa: 'DEFESA E GOVERNO',
			farmacos: 'FÁRMACOS',
			industrial: 'INDUSTRIAL',
			moda: 'MODA',
			quimicos: 'QUÍMICOS',
			tecnologia: 'TECNOLOGIA',
			alimenticios: 'ALIMENTÍCIOS',
			transporte: 'TRANSPORTE',
			biotecnologia: 'BIOTECNOLOGIA',
			pesquisa: 'PESQUISA E MELHORAMENTO GENÉTICO',
			medico: 'MÉDICO - HOSPITALAR',
			comunicacao: 'COMUNICAÇÃO',
			maquinarios: 'MAQUINÁRIOS E SEUS ACESSÓRIOS'
		},

		contato: {
			nome: 'Nome',
			email: 'E-mail',
			telefone: 'Telefone',
			mensagem: 'Mensagem'
		},

		footer: {
			brasil: 'Brasil'
		}
	
}