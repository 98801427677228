import React, { Component } from 'react';

import Translate from 'react-translate-component';
import counterpart from 'counterpart';

import header2 from '../Header2/Header2';

import pt from '../../translate/pt';
import en from '../../translate/en';
import es from '../../translate/es';

counterpart.registerTranslations('pt', pt);
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('es', es);



counterpart.setLocale('pt');

class UpdateImg extends Component {

	render() {

		const imgBanner = counterpart.translate('banner.mercados');

		return(
			<img src={imgBanner} />
		)
	}
}

export default UpdateImg;