export default {
	
	menu: {
		home: 'Home',
		institucional: 'INSTITUTIONAL',
		network: 'NETWORK',
		servicos: 'SERVICES',
		projetos: 'PROJECTS',
		mercados: 'MARKETS SERVICED',
		contato: 'CONTACT US',
		blog: 'BLOG',
		intranet: 'INTRANET',
		trabalhe: 'CAREERS',
		rastreamento: 'TRACKING'
	},

	banner: {
		institucional: require('../assets/img/capa-institucionalEN.jpg'),
		servicos: require('../assets/img/capa-servicosEN.jpg'),
		projetos: require('../assets/img/capa-projetosEN.jpg'),
		mercados: require('../assets/img/capa-mercados-atendidosEN.jpg'),
	},

	buttons: {
		saibaMais: 'Learn More',
		enviar: 'Send',
		entrar: 'Log in'
	},

	titulos: {
		servicos: 'Services',
		institucional: 'About Us',
		projetos: 'Projects',
		networking: 'International Networking',
		mercados: 'Markets Serviced',
		contato: 'Contact Us'
	},

	home: {
		banner: {
			txtBannerServicos: 'Delivering high quality, prices, and logistical solutions to clients in its services, GP Cargo has achieved a respected position in the market, proving to be one of a kind in the load shipping industry.',
			txtBannerInstitucional: 'GP Cargo’s headquarters is in Belo Horizonte, Brazil, and it has a global network of highly qualified representatives, who, together with GP Cargo, offer all the services making up the logistics chain, honoring the level of quality promised.'
		},
		txtProjetos: 'Besides its regular shipments, GP Cargo has considerable experience of coordinating project load shipments.',
		txtNetworking: 'Our global presence lets us operate with our clients’ integrated logistics.'
	},

	institucional: {
		titulos: {
			quemSomos: 'About Us',
			valores: 'Values',
			certificacoes: 'Certifications',
			missao: 'Mission',
			politica: 'Quality Policy'
		},
		textos: {
			quemSomos: {
				primeiro: 'We are GP Cargo, an international shipping company specialized in complex logistical projects, integrated logistical analysis, customs clearance, and national and international transport.',
				segundo: 'We started up in 2009 with our headquarters located in Belo Horizonte, in the state of Minas Gerais (Brazil). Over the years, we gained several highly qualified representatives and partners in Brazil and around the world.',
				terceiro: 'When we completed 10 years of operation in 2019, we reformulated our branding in order to bring more innovation, preparing ourselves for another decade of achievements.',
				quarto: 'To do so, we believe challenge is our best motivator, so we avidly seek bold solutions to our clients’ processes. And we will keep on offering complete solutions to our clients door to door in order to provide easy understanding of foreign trade complex processes.' 
			} ,
				valores: {
				primeiro: 'Our values are:',
				segundo: '- ethics;',
				terceiro: '- transparency and reliability in relations;',
				quarto: '- excellence in operations;',
				quinto: '- respect for the team and personal development;',
				sexto: '- social responsibility.'
			} ,
			certificacoes: {
				primeiro: 'We were one of the first freight transport companies in Brazil to receive the title of Authorized Economic Operator from the Brazilian Internal Revenue Service.',
				segundo: 'We are very proud of this certification that confirms the commitment and quality of our services.',
				terceiro: 'We are also ISO 9001:2015 certified since 2015, and GPTW certified since 2017.',
				quarto: 'Furthermore, we earned a certification from the AOG Group confirming we are qualified to work with urgent critical loads.'
			},
			missao: 'Through the integrated development of our team, to strengthen our clients’ businesses and promote the excellence of our partners around the world in order to offer truly intelligent solutions.',
			politica: 'We become immersed in our clients’ processes in order to understand and analyze their needs. Therefore, we simplify and customize processes in order to make improvements and to ensure that the transport of goods is efficient, with appropriate communication. As a result, we avoid wasting time and resources, and we provide quality assurance.'
		},

		network: {
			primeiro: 'Our global operation facilitates processes together with the integrated logistics of our clients. Regardless of the place of departure, storage, traffic, and arrival, we offer the same high standard of quality.',
			segundo: 'Since operational excellence is one of our values, promoting our partners’ excellence is also part of our mission to ensure the high quality we promise our clients.',
			terceiro: 'We are part of an international networking of qualified agents called WFG (World Freight Group), and we are also certified to operate loads by AOG (Aircraft On Ground), making us part of the AOG Freight 247 and to count on a huge number of specialized partners in the load market.',
			quarto: 'We are also a part of a distinguished group of logistical operators called XLP, which specializes in large loads and special projects.'
		},

	},

	network: {
			titulo1: 'International Networking',
			titulo2: 'Groups',
			texto: {
				primeiro: 'Our global operation facilitates processes together with the integrated logistics of our clients. Regardless of the place of departure, storage, traffic, and arrival, we offer the same high standard of quality.',
				segundo: 'Since operational excellence is one of our values, promoting our partners’ excellence is also part of our mission to ensure the high quality we promise our clients.',
				terceiro: 'We are part of an international networking of qualified agents called WFG (World Freight Group), and we are also certified to operate loads by AOG (Aircraft On Ground), making us part of the AOG Freight 247 and to count on a huge number of specialized partners in the load market.',
				quarto: 'We are also a part of a distinguished group of logistical operators called XLP, which specializes in large loads and special projects.',
			}
		},

	servicos: {
			topo: {
				primeiro: 'We offer high quality services at competitive prices.',
				segundo: 'Learn more about our areas of operation:'
			},
			modalAereo: {
				titulo: 'AIR TRANSPORT',
				texto: {
					primeiro: 'We are an IATA-CASS accredited agent, and we work in partnership with the world’s premier airlines so we can offer safe transport, for both import and export, to major airports around the world.',
					segundo: 'Our services include regular shipments as well as chartering vehicles for urgent load transport of all sizes and quantities.'
				}
			},
			modalMaritimo: {
				titulo: 'SEA TRANSPORT',
				texto: 'We have agreements with reliable and efficient shipowners, and we operate in any type of shipment, from the simple (LCLs) to the complex, such as ship charters, RO/RO shipment, breakbulk, and bulk.'
			},
			modalRodoviario: {
				titulo: 'ROAD TRANSPORT',
				texto: 'We have a wide selection of road companies as partners, so we operate by hiring and coordinating freights, as well as by transporting large loads for project transport.'
			},
			modalFerroviario: {
				titulo: 'RAIL TRANSPORT',
				texto: 'We have a close relationship with our main contractors, which helps us provide higher security and more competitive prices for import and export transport.'
			},
			consultoria: {
				titulo: 'CONSULTING ',
				texto: {
					primeiro: 'We provide specialized consulting in the following areas:',
					segundo: '- integrated logistics analysis;',
					terceiro: '- implementation of procurement;',
					quarto: '- logistical study of shipments;',
					quinto: '- advance study of the project, accounting for clearance by customs authorities;',
					sexto: '- tax assistance, focusing on any possible tax benefits that the client may have;',
					setimo: '- legal assistance through analyzing international agreements;',
					oitavo: '- international insurance for any types of shipment and load.'
				}
			},
			desembaracoAduaneiro: {
				titulo: 'CUSTOMS CLEARANCE',
				texto: 'We have a well-trained team to help in one of the most critical stages of the international logistics chain: customs clearance. We are able to assist you in regular import or export procedures, or with any special requirements, or even assist you with the study and implementation of tax benefits when importing. As a result, we are able to assist any type of demand in this field.'
			},
			rastreamentoOnline: {
				titulo: 'ONLINE TRACKING',
				texto: 'Through a smart tracking system called GP LOG, we provide our clients with daily information about the progress of their projects, their load location, and fast access to all documentation.'
			},

		},

		projetos: {
			projetos: {
				titulo: 'Projects',
				texto: {
					primeiro: 'Our experience includes coordinating project load shipments. This type of load is considered special in our company due to its complexity.',
					segundo: 'Our focus is excellence from implementation until the end of every project. To do so, we apply our team’s knowledge to quantitative analysis tools focused on improving quality and reducing costs and environmental impact.'
				}
			},
			etapas: {
				titulo: 'Stages of our Projects',
				texto: {
					primeiro: '1. Meetings to understand the particularities of the project and our client’s needs.',
					segundo: '2. Analysis of costs, risks, and goals.',
					terceiro: '3. Tactical, strategical, and contingency-plan development with a project assessment conducted by our legal, customs, tax, and accounting departments.',
					quarto: '4. Project implementation and follow-up.',
				}
			}
		},

		mercados: {
			banner: 'LinkEN',
			automotivo: 'AUTOMOTIVE',
			bens: 'ASSETS AND COMMODITIES',
			defesa: 'DEFENSE AND GOVERNMENT',
			farmacos: 'MEDICATIONS',
			industrial: 'INDUSTRIAL',
			moda: 'FASHION',
			quimicos: 'CHEMICAL',
			tecnologia: 'TECHNOLOGY',
			alimenticios: 'FOOD',
			transporte: 'TRANSPORT',
			biotecnologia: 'BIOTECHNOLOGY',
			pesquisa: 'GENETIC RESEARCH AND IMPROVEMENT',
			medico: 'MEDICAL - HOSPITAL',
			comunicacao: 'COMMUNICATION',
			maquinarios: 'MACHINERY AND ITS PARTS'
		},

		contato: {
			nome: 'Name',
			email: 'Email',
			telefone: 'Phone number',
			mensagem: 'Message'
		},

		footer: {
			brasil: 'Brazil'
		}

}