export default {
	menu: {
		home: 'Página Inicial',
		institucional: 'INSTITUCIONAL',
		network: 'NETWORK',
		servicos: 'SERVICIOS',
		projetos: 'PROYECTOS',
		mercados: 'MERCADOS ATENDIDOS',
		contato: 'CONTACTO',
		blog: 'BLOG',
		intranet: 'INTRANET',
		trabalhe: 'TRABAJÁ CON NOSOTROS',
		rastreamento: 'RASTREO'
	},

	banner: {
		institucional: require('../assets/img/capa-institucionalES.jpg'),
		servicos: require('../assets/img/capa-servicosES.jpg'),
		projetos: require('../assets/img/capa-projetosES.jpg'),
		mercados: require('../assets/img/capa-mercados-atendidosPT.jpg'),
	},

	buttons: {
		saibaMais: 'Aprender Más',
		enviar: 'Enviar',
		entrar: 'Ingressar'
	},

	titulos: {
		servicos: 'Servicios',
		institucional: 'Institucional',
		projetos: 'Proyectos',
		networking: 'Newtworking Internacional',
		mercados: 'Mercados Atendidos',
		contato: 'Contacto'
	},

	home: {
		banner: {
			txtBannerServicos: 'Proporcionando alta calidad en los servicios prestados, precios competitivos y soluciones logísticas al cliente, GP Cargo ha logrado su lugar en el mercado y demostrado un factor diferencial nunca antes visto en el sector de despacho de cargas.',
			txtBannerInstitucional: 'GP Cargo tiene su oficina central en Belo Horizonte, Brasil, y cuenta con una red de socios globales altamente capacitados que, juntamente con GP Cargo, brindan todos los servicios integrados a la cadena logística, honrando el nivel de calidad propuesto. '
		},
		txtProjetos: 'Paralelamente a los embarques regulares, GP Cargo posee una larga experiencia en gestionar embarques de carga de proyectos.',
		txtNetworking: 'Nuestra presencia global facilita la operación en conjunto con la logística integrada de nuestros clientes.'
	},

	institucional: {
		titulos: {
			quemSomos: 'Quiénes Somos',
			valores: 'Valores',
			certificacoes: 'Certificaciones',
			missao: 'Misión',
			politica: 'Política de Calidad'
		},
		textos: {
			quemSomos: {
				primeiro: 'Somos GP Cargo, un agente internacional de cargas especialista en proyectos logísticos complejos, análisis de logística integrada, despacho aduanero y transporte nacional e internacional.',
				segundo: 'Iniciamos nuestras actividades en 2009, con nuestra oficina central ubicada en Belo Horizonte, Minas Gerais, Brasil, y desde entonces hemos conseguido representantes y socios altamente capacitados en todo el territorio brasileño y mundial.',
				terceiro: 'Al completar 10 años en 2019, reformulamos nuestra marca y afrontamos la innovación, preparándonos para 10 años más de logros.',
				quarto: 'Para ello, estamos cada vez más conscientes de que somos impulsados por los retos y valoramos la búsqueda incesante de soluciones audaces para los procesos de nuestros clientes. Así, seguiremos brindando soluciones completas puerta a puerta, proporcionando facilidad en el entendimiento de los procesos robustos del comercio exterior' 
			} ,
			valores: {
				primeiro: 'Nuestros valores son:',
				segundo: '- ética;',
				terceiro: '- transparencia y confianza en las relaciones;',
				quarto: '- excelencia operacional;',
				quinto: '- respeto al equipo y desarrollo de personas;',
				sexto: '- responsabilidad social.'
			} ,
			certificacoes: {
				primeiro: 'Hemos sido uno de los primeros agentes de carga de Brasil en recibir de la Recaudación Federal Brasileña el título de Operador Económico Autorizado. Sentimos un gran orgullo por esta certificación que demuestra la seriedad y calidad de nuestros servicios.',
				segundo: 'También estamos certificados en ISO 9001:2015 desde 2015,',
				terceiro: 'y contamos con la certificación GPTW desde 2017.',
				quarto: 'Además, poseemos la certificación del grupo AOG como agente apto para trabajar con cargas críticas urgentes.'
			},
			missao: 'A través del crecimiento integral de nuestro equipo, nuestra misión es profundizar en el negocio de nuestros clientes y promover la excelencia de nuestros socios en todo el mundo para brindar soluciones realmente inteligentes.',
			politica: 'Nos sumergimos en los procesos de nuestros clientes, analizando y entendiendo sus necesidades. Así, lo simplificamos para mejorar y lo personalizamos para innovar, garantizando que el movimiento de bienes se realice de manera eficiente, con comunicación adecuada entre todos los involucrados, evitando desperdicio de tiempo y recursos y proporcionando la garantía de nuestra calidad.'
		},

			network: {
				primeiro: 'Nuestra actuación global facilita la operación con la logística integrada de nuestros clientes. Independientemente del lugar de partida, almacenaje, tránsito y llegada, operamos con el mismo estándar de calidad propuesto.',
				segundo: 'Así como la excelencia operacional es uno de nuestros valores, promover también la excelencia de nuestros socios en todo el mundo forma parte de nuestra misión, resultando en la garantía de calidad que proporcionamos a nuestros clientes.',
				terceiro: 'Somos parte de la red internacional de agentes calificados WFG (World Freight Group) y también estamos certificados para la operación de cargas AOG (Aircraft On Ground), lo que nos ha llevado a integrar la red AOG Freight 247 y a poder contar con la colaboración de agentes especializados en el segmento de cargas.',
				quarto: 'Somos parte del selecto grupo de operadores logísticos XLP, especializado en cargas de gran volumen y proyectos especiales.'
			},
		},

		network: {
			titulo1: 'Networking Internacional',
			titulo2: 'Grupos',
			texto: {
				primeiro: 'Nuestra actuación global facilita la operación con la logística integrada de nuestros clientes. Independientemente del lugar de partida, almacenaje, tránsito y llegada, operamos con el mismo estándar de calidad propuesto.',
				segundo: 'Así como la excelencia operacional es uno de nuestros valores, promover también la excelencia de nuestros socios en todo el mundo forma parte de nuestra misión, resultando en la garantía de calidad que proporcionamos a nuestros clientes',
				terceiro: 'Somos parte de la red internacional de agentes calificados WFG (World Freight Group) y también estamos certificados para la operación de cargas AOG (Aircraft On Ground), lo que nos ha llevado a integrar la red AOG Freight 247 y a poder contar con la colaboración de agentes especializados en el segmento de cargas.',
				quarto: 'Somos parte del selecto grupo de operadores logísticos XLP, especializado en cargas de gran volumen y proyectos especiales.'
			}
		},

		servicos: {
			topo: {
				primeiro: 'Proveemos servicio de alta calidad a precios competitivos.',
				segundo: 'Conozca nuestras áreas de actuación:'
			},
			modalAereo: {
				titulo: 'TRANSPORTE AÉREO',
				texto: {
					primeiro: 'Somos un agente acreditado por IATA-CASS y operamos en colaboración con las principales compañías aéreas del mundo. Así, podemos proporcionar un transporte seguro, tanto en la importación como en la exportación, en los principales aeropuertos del mundo. ',
					segundo: 'Nuestros servicios van desde embarques regulares hasta la contratación de charters para el movimiento de cargas urgentes de cualquier tamaño o cantidad.'
				}
			},
			modalMaritimo: {
				titulo: 'TRANSPORTE MARÍTIMO',
				texto: 'Nuestros contratos son firmados con armadores de primera línea y trabajamos con todos los tipos de embarque, desde los simples (LCL) hasta los más complejos, como fletamento de buques, embarques de cargas (RO/RO), break-bulk y bulk.'
			},
			modalRodoviario: {
				titulo: 'TRANSPORTE POR CARRETERA',
				texto: 'En cooperación con compañías de transporte por carretera seleccionadas, actuamos en la contratación y coordinación de flete y el transporte por carretera de cargas de gran volumen para transporte de proyectos.'
			},
			modalFerroviario: {
				titulo: 'TRANSPORTE FERROVIARIO ',
				texto: 'Tenemos una relación cercana con las principales concesionarias, lo que nos permite ofrecer mayor seguridad y precios competitivos en el transporte en importaciones y exportaciones.'
			},
			consultoria: {
				titulo: 'CONSULTORÍA ',
				texto: {
					primeiro: 'Brindamos una línea de consultoría especializada en:',
					segundo: '- análisis da logística integrada;',
					terceiro: '- ejecución del trabajo de aprovisionamiento;',
					quarto: '- estudio logístico de embarques;',
					quinto: '- estudio previo del proyecto junto a las autoridades aduaneras para el despacho;',
					sexto: '- consultoría tributaria, buscando posibles beneficios fiscales de los que el cliente pueda disfrutar;',
					setimo: '- asesoría jurídica en el análisis de contratos internacionales;',
					oitavo: '- seguros internacionales especializados para cualquier tipo de embarque y carga.'
				}
			},
			desembaracoAduaneiro: {
				titulo: 'DESPACHO ADUANERO',
				texto: 'Contamos con un equipo especializado en una de las etapas más críticas de la cadena logística internacional: el despacho aduanero. Ya sea en exportaciones o importaciones regulares, pasando por regímenes especiales hasta el estudio y ejecución de beneficios tributarios en las importaciones, estamos capacitados para atender cualquier tipo de demanda en este tema.'
			},
			rastreamentoOnline: {
				titulo: 'RASTREO EN LÍNEA',
				texto: 'Por medio de un sistema inteligente de rastreo, el GP LOG, proveemos a nuestros clientes información diaria sobre el progreso de sus proyectos, localización de sus cargas y acceso rápido a toda su documentación.'
			},

		},

		projetos: {
			projetos: {
				titulo: 'Proyectos',
				texto: {
					primeiro: 'Tenemos experiencia en gestionar embarques de carga de proyectos. Este tipo de carga tiene destaque especial dentro de nuestra empresa debido a su complejidad.',
					segundo: 'Nuestro enfoque es buscar la excelencia en calidad desde la implementación hasta la conclusión de cada proyecto. Para ello, unimos el conocimiento de nuestro equipo a herramientas de análisis cuantitativo enfocadas en elevar la calidad y reducir los costos y el impacto ambiental.'
				}
			},
			etapas: {
				titulo: 'Etapas de Nuestros Proyectos',
				texto: {
					primeiro: '1. Reuniones para el entendimiento de las especificidades del proyecto y de las necesidades de nuestros clientes.',
					segundo: '2. Análisis de costos, riesgos y demandas.',
					terceiro: '3. Desarrollo del plan táctico, estratégico y de contingentación, con evaluación del proyecto junto a los departamentos jurídico, aduanero, tributario y contable.',
					quarto: '4. Seguimiento y ejecución del proyecto.',
				}
			}
		},

		mercados: {
			automotivo: 'AUTOMOTIVO',
			bens: 'BIENES Y CONSUMO',
			defesa: 'DEFENSA Y GOBIERNO',
			farmacos: 'FÁRMACOS',
			industrial: 'INDUSTRIAL',
			moda: 'MODA',
			quimicos: 'QUÍMICOS',
			tecnologia: 'TECNOLOGÍA',
			alimenticios: 'ALIMENTARIOS',
			transporte: 'TRANSPORTE',
			biotecnologia: 'BIOTECNOLOGÍA',
			pesquisa: 'INVESTIGACIÓN Y MEJORAMIENTO GENÉTICO',
			medico: 'MÉDICO - HOSPITALAR',
			comunicacao: 'COMUNICACIÓN',
			maquinarios: 'MAQUINARIA Y SUS ACCESORIOS'
		},

		contato: {
			nome: 'Nombre',
			email: 'E-mail',
			telefone: 'Teléfono',
			mensagem: 'Mensaje'
		},

		footer: {
			brasil: 'Brasil'
		}
	
}